import React, { Component } from 'react';

class Form extends Component {
  state = {
    formSubmitted: false
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ formSubmitted: true });

    //prevents spam
    if (event.target.sc.value!=='') {
      return;
    }

    const data = new FormData(event.target);
    data.delete("sc");

    fetch('https://script.google.com/macros/s/AKfycbwd-7sytf6hBvDWIMSml16sHAoROfrB_huRqmILCWcLryJooKE2/exec', {
      method: 'POST',
      body: data,
    });
  };
  render() {
    return (
      <form onSubmit={this.handleSubmit} autoComplete="on" name="contact-form">
        <div className="form-group">
          <input name="sc" type="hidden" value=""/>
          <input name="Formulário" type="hidden" value={this.props.formName} required/>
          {this.props.children}
          {
            this.state.formSubmitted ?
              <div className="alert alert-success">Obrigado, em breve entraremos em contato.</div> :
              <button type="submit" className="btn btn-block ui-dark-sc shadow-md">Enviar</button>
          }
        </div>
      </form>
    )
  }
}
export default Form

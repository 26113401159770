import React from 'react'
import './css/ecommerce.scss'
import SEO from "../components/seo"
import Layout from "../components/layout";
import Form from "../components/form"

const Contact = () => (
  <Layout>
    <SEO title="Fale Conosco" description="Neste espaço você pode esclarecer suas dúvidas, dar sua opinião ou sugestão e falar com a gente sobre outros assuntos que desejar." keywords={[`contato`, `fale conosco`]} />
    <div className="main" role="main">
      <div className="ui-hero hero-lg ui-gradient-sc ui-waves hero-svg-layer-1 sp-horizontal sp-has-buttons">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center" data-vertical_center="true" data-vertical_offset="16">
              <div className="col-md-12 text-center" data-vertical_center="true" data-vertical_offset="16">
                <h1 className="animate fade-in-up-big" data-show="fade-in-up-big" data-delay="100">
                  Fale Conosco
                </h1>
                <p className="text-white">Estamos anciosos pelo seu contato.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="section-heading mb-2">
                <p>
                  Neste espaço você pode esclarecer suas dúvidas, dar sua opinião ou sugestão
                  e falar com a gente sobre outros assuntos que desejar.
                </p>
              </div>
              <Form formName="Contato">
                <div className="form-group">
                  <input name="Nome" type="text" placeholder="Nome *" className="form-control" required/>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-6">
                      <input type="email" autoComplete="email" className="input form-control" name="Email" placeholder="E-mail *" required />
                    </div>
                    <div className="col-sm-6">
                      <input autoComplete="tel" className="input form-control" name="Telefone" placeholder="Telefone *" required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <input className="input form-control" name="Assunto" placeholder="Assunto *" required />
                </div>
                <div className="form-group">
                  <textarea name="Mensagem"  type="text" placeholder="Mensagem *" className="form-control" rows="5" required/>
                </div>
              </Form>
            </div>
            <div className="col-md-5">
              <div className="ui-card form-card shadow-xl bg-indigo">
                <div className="card-header pb-0">
                  <h3 className="heading">Contato</h3>
                </div>
                <div className="card-body">
                  <ul className="ui-icon-blocks ui-blocks-v">
                    <li className="ui-icon-block">
                      <span className="icon icon-clock"></span>
                      <p>Seg. a Sex. das 08:00 às 18:00</p>
                    </li>
                    <li className="ui-icon-block">
                      <span className="icon icon-phone"></span>
                      <p>+55 15 9 9675-6859</p>
                    </li>
                    <li className="ui-icon-block">
                      <span className="icon icon-envelope"></span>
                      <p>contato@systemcode.com.br</p>
                    </li>
                    <li className="ui-icon-block">
                      <span className="icon icon-pin"></span>
                      <p>Rua Armando Dias, 44 - Centro - Sala 02<br/>Porto Feliz - SP<br/>CEP 18540-000</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      { /*<div id="gmap" className="ui-map" data-lat="-23.223900" data-lng="-47.525772"
           data-marker={mapMarker}
           data-style="style-1" data-zoom="18"></div> */ }
    </div>
  </Layout>
)

export default Contact